/**
 * 任务项接口定义
 *//**
 * 任务队列管理器
 * 用于管理和控制异步任务的并发执行
 * @template T 任务执行结果的类型
 */export default class TaskQueueManager{/**
     * 构造函数
     * @param maxConcurrent 最大并发数，默认为 6
     */constructor(){let maxConcurrent=arguments.length>0&&arguments[0]!==undefined?arguments[0]:6;/** 最大并发数 */this.maxConcurrent=void 0;/** 等待执行的任务队列 */this.waitingQueue=void 0;/** 当前正在执行的任务数量 */this.runningCount=void 0;/** 存储任务执行结果的 Map */this.results=void 0;/** 正在执行的任务 ID 集合 */this.runningTasks=void 0;/** 记录任务开始时间的 Map */this.taskStartTimes=void 0;// 新增任务检查Map
this.pendingTasks=new Map();this.maxConcurrent=maxConcurrent;this.waitingQueue=[];this.runningCount=0;this.results=new Map();this.runningTasks=new Set();this.taskStartTimes=new Map();}/**
     * 获取格式化的时间字符串
     * @returns 格式化的时间字符串 (HH:mm:ss)
     */getTimeString(){return new Date().toISOString().slice(11,19);// 使用 slice 替代 substr
}/**
     * 添加新任务到队列
     * @param taskId 任务唯一标识符
     * @param requestFn 任务执行函数
     * @returns Promise，resolve 时返回任务执行结果
     */addTask(taskId,requestFn){if(this.pendingTasks.has(taskId)){return Promise.reject('任务已存在');}this.pendingTasks.set(taskId,true);console.log("".concat(this.getTimeString()," [\u961F\u5217] \u6DFB\u52A0\u65B0\u4EFB\u52A1: ").concat(taskId));return new Promise((resolve,reject)=>{const taskItem={id:taskId,fn:requestFn,resolve,reject};this.waitingQueue.push(taskItem);this.processQueue();});}/**
     * 处理队列中的待执行任务
     * 当有等待中的任务且未达到最大并发数时，继续执行新的任务
     */processQueue(){if(this.waitingQueue.length>0||this.runningCount>0){console.log("\n".concat(this.getTimeString()," [\u72B6\u6001] \u8FD0\u884C\u4E2D: ").concat(this.runningCount,"/").concat(this.maxConcurrent,", \u7B49\u5F85\u4E2D: ").concat(this.waitingQueue.length));if(this.runningCount>0){console.log('[并发执行中]:',Array.from(this.runningTasks).join(', '));}}while(this.waitingQueue.length>0&&this.runningCount<this.maxConcurrent){const task=this.waitingQueue.shift();this.executeTask(task);}}/**
     * 执行单个任务
     * @param task 要执行的任务项
     */async executeTask(task){this.runningCount++;this.runningTasks.add(task.id);this.taskStartTimes.set(task.id,Date.now());console.log("".concat(this.getTimeString()," [\u5F00\u59CB] ").concat(task.id));try{const result=await task.fn();const duration=Date.now()-this.taskStartTimes.get(task.id);console.log("".concat(this.getTimeString()," [\u5B8C\u6210] ").concat(task.id," (\u8017\u65F6: ").concat(duration,"ms)"));// this.results.set(task.id, result);
task.resolve(result);}catch(error){console.log("".concat(this.getTimeString()," [\u5931\u8D25] ").concat(task.id));// this.results.set(task.id, { error });
task.reject(error);}finally{this.runningCount--;this.runningTasks.delete(task.id);this.taskStartTimes.delete(task.id);this.pendingTasks.delete(task.id);// 清理任务
this.processQueue();}}/**
     * 清空任务队列和相关状态
     */clear(){this.waitingQueue=[];this.results.clear();this.runningTasks.clear();this.taskStartTimes.clear();console.log('[系统] 已清空任务队列');}}