import{SystemEnum}from"./enum";const isDebugMode=()=>{return localStorage.getItem("isDebug")==='true';};export const PublicPath=['/',// 首页
'/indicator/detail',// 指标详情
'/indicator/viewExamine'// 查看审核
];// 个人空间路径，个人路径不受权限控制
export const PersonalPath=['/cost/list'// 指标详情
];// 个人菜单 取 menuList key值。固定没有权限限制
export const PersonMenuKeys=['cost','cost/list'];// 权限配置，code，name,路由，多对多。
export const AuthConfig=[// **********系统菜单********
{path:'/project-center/list',headMenuKey:SystemEnum.SYSTEM,code:'630:project:list:query',name:'指标项目列表'},// { path: '/project-center/englist', headMenuKey: SystemEnum.ZBGL, code: '', name: '指标工程列表' },
// { path: '/project-center/englist/edit', headMenuKey: SystemEnum.ZBGL, code: '', name: '指标工程列表' },
// 数据字典
{path:'/dict/professional',headMenuKey:SystemEnum.SYSTEM,code:'',name:'专业信息'},{path:'/dict/total',headMenuKey:SystemEnum.SYSTEM,code:'',name:'总体专业'},{path:'/dict/project',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程专业'},{path:'/dict/sort',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程分类'},{path:'/dict/info',headMenuKey:SystemEnum.SYSTEM,code:'',name:'项目信息'},{path:'/dict/baseDict',headMenuKey:SystemEnum.SYSTEM,code:'',name:'基础项目信息'},{path:'/dict/info2',headMenuKey:SystemEnum.SYSTEM,code:'630:sysdict:project:query',name:'项目信息2'},{path:'/dict/projectCategory',headMenuKey:SystemEnum.SYSTEM,code:'630:sysdict:engclass:query',name:'工程分类'},{path:'/dict/feature',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程特征'},{path:'/dict/feature2',headMenuKey:SystemEnum.SYSTEM,code:'630:sysdict:feature:query',name:'工程特征'},{path:'/dict/projectStep',headMenuKey:SystemEnum.SYSTEM,code:'',name:'项目阶段'},{path:'/dict/projectSpecial',headMenuKey:SystemEnum.SYSTEM,code:'630:sysdict:major:query',name:'工程专业'},{path:'/dict/type',headMenuKey:SystemEnum.SYSTEM,code:'',name:'造价类型'},{path:'/dict/template',headMenuKey:SystemEnum.SYSTEM,code:'',name:'指标模板'},// 内部数据字典
{path:'/systemDict/professional',headMenuKey:SystemEnum.SYSTEM,code:'',name:'专业信息',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/total',headMenuKey:SystemEnum.SYSTEM,code:'',name:'总体专业',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/project',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程专业',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/sort',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程分类',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/info',headMenuKey:SystemEnum.SYSTEM,code:'',name:'项目信息',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/baseDict',headMenuKey:SystemEnum.SYSTEM,code:'',name:'基础项目信息',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/projectCategory',headMenuKey:SystemEnum.SYSTEM,code:'',name:'项目信息2',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/info2',headMenuKey:SystemEnum.SYSTEM,code:'',name:'项目信息2',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/info3',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程信息',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/feature',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程特征',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/feature2',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程特征',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/projectStep',headMenuKey:SystemEnum.SYSTEM,code:'',name:'项目阶段',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/projectSpecial',headMenuKey:SystemEnum.SYSTEM,code:'',name:'工程专业',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/type',headMenuKey:SystemEnum.SYSTEM,code:'',name:'造价类型',auth:{isSuperAdmin:true,isDefaultEpid:true}},{path:'/systemDict/template',headMenuKey:SystemEnum.SYSTEM,code:'',name:'指标模板',auth:{isSuperAdmin:true,isDefaultEpid:true}},// 项目模板
{path:'/template/list',headMenuKey:SystemEnum.YJGL,code:'630:basicdata:project:query',name:'项目模板'},{path:'/field/list',headMenuKey:SystemEnum.YJGL,code:'630:basicdata:fieldset:query',name:'字段设置'},// { path: '/dict/professional', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:ztzy:query', name: '专业信息' },
// { path: '/dict/total', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:ztzy:query', name: '总体专业' },
// { path: '/dict/project', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:gczy:query', name: '工程专业' },
// { path: '/dict/sort', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:gcfl:query', name: '工程分类' },
// { path: '/dict/info', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:xmxx:query', name: '项目信息' },
// { path: '/dict/feature', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:gctz:query', name: '工程特征' },
// { path: '/dict/type', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zjlx:query', name: '造价类型' },
// { path: '/dict/template', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zbmb:query', name: '指标模板' },
// { path: '/official-template/list', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zfzbmb:query', name: '政府指标模板' },
// { path: '/official-template/open', headMenuKey: HeaderMenuEnum.szzc, code: '630:dict:zfzbmb:open', name: '政府指标模板开放' },
// 基础清单定额库
{path:'/inventory/list',headMenuKey:SystemEnum.SYSTEM,code:'630:listlib:qd:query',name:'清单数据'},{path:'/inventory/ration',headMenuKey:SystemEnum.SYSTEM,code:'630:listlib:de:query',name:'定额数据'},{path:'/inventory/laborMaterialsMachinery',code:'630:listlib:rcj:query',headMenuKey:SystemEnum.SYSTEM,name:'人材机数据'},// 成本科目库
{path:'/cost/list',headMenuKey:SystemEnum.SYSTEM,code:'',name:'成本科目库'},{path:'/cost/split',headMenuKey:SystemEnum.SYSTEM,code:'',name:'自动拆分方案'},// 项目模板
// { path: '/template/list', headMenuKey: HeaderMenuEnum.jcsj, code: '630:basicnf:projtempl:query', name: '项目模板' },
// { path: '/field/list', headMenuKey: HeaderMenuEnum.jcsj, code: '', name: '字段设置' },
// 后台日志
{path:'/log/list',headMenuKey:SystemEnum.YJGL,code:'630:syslog:oplog:query',name:'操作日志'},// **********指标********
// 造价指标-指标工程列表
{path:'/indicator/englist',headMenuKey:SystemEnum.ZBGL,code:'630:indcoll:cost:query',name:'指标工程列表'},{path:'/indicator/englist/edit',headMenuKey:SystemEnum.ZBGL,code:'630:indcoll:cost:update',name:'指标工程列表'},{path:'/indicator/application',headMenuKey:SystemEnum.ZBGL,code:'630:indcoll:report:query',name:'指标应用列表'},{path:'/indicator/list',headMenuKey:SystemEnum.ZBGL,code:'',name:'指标项目列表'},{path:'/indicator/officialTemplate',headMenuKey:SystemEnum.ZBGL,code:'',name:'政府指标模板'},// 指标查询
{path:'/indicator-view/km',headMenuKey:SystemEnum.ZBGL,code:'630:indquery:subj:query',name:'科目指标'},{path:'/indicator-view/eng',headMenuKey:SystemEnum.ZBGL,code:'630:indquery:project:query',name:'工程指标'},{path:'/indicator-view/qdzhdj',headMenuKey:SystemEnum.ZBGL,code:'630:indquery:qd:query',name:'清单综合单价'},{path:'/indicator-view/glj',headMenuKey:SystemEnum.ZBGL,code:'630:indquery:rcj:query',name:'工料机价格'},{path:'/indicator/officialTemplate2',headMenuKey:SystemEnum.ZBGL,code:'630:indtmpl:reporttmpl:query',name:'政府指标模板'},{path:'/indicator/official-template/open',headMenuKey:SystemEnum.ZBGL,code:'',name:'政府指标模板开放'},{path:'/official-template/newSetting',headMenuKey:SystemEnum.ZBGL,code:'',name:'政府指标模板设置'},{path:'/indicator/edit',headMenuKey:SystemEnum.ZBGL,code:'',name:'指标编辑'},{path:'/indicator/add',headMenuKey:SystemEnum.ZBGL,code:'',name:'新增造价类型'},// 指标报表
{path:'/indicator/report',headMenuKey:SystemEnum.ZBGL,code:'',name:'指标报表'},// 项目对比
{path:'/indicator/singlecompare',headMenuKey:SystemEnum.ZBGL,code:'',name:'单项目对比'},{path:'/indicator/multicompare',headMenuKey:SystemEnum.ZBGL,code:'',name:'多项目对比'},{path:'/indicator/reviewSingle',headMenuKey:SystemEnum.ZBGL,code:'',name:'多项目对比'},{path:'/indicator/reviewMulti',headMenuKey:SystemEnum.ZBGL,code:'',name:'多项目对比'},// 待审核指标列表
{path:'/indicator/reviewList',headMenuKey:SystemEnum.ZBGL,code:'',name:'待审核指标列表'},{path:'/indicator/examine',headMenuKey:SystemEnum.ZBGL,code:'',name:'指标审核'},{path:'/indicator/report',headMenuKey:SystemEnum.ZBGL,code:'',name:''},// 数据字典
{path:'/zjDict/info3',headMenuKey:SystemEnum.ZBGL,code:'630:dict2:project:query',name:'工程信息'},{path:'/zjDict/subjectAttr',headMenuKey:SystemEnum.ZBGL,code:'630:dict2:itemattr:query',name:'科目属性'},{path:'/zjDict/allocationRules',headMenuKey:SystemEnum.ZBGL,code:'630:dict2:allorule:query',name:'分摊规则'},// ...(isHighAuth() ? [{ path: '/systemData/glj', headMenuKey: SystemEnum.ZBGL, code: '', name: '鲁班工料机' }] : []),
{path:'/systemData/glj',headMenuKey:SystemEnum.ZBGL,code:'',name:'鲁班工料机'},{path:'/systemData/unit',headMenuKey:SystemEnum.ZBGL,code:'',name:'系统单位'},{path:'/systemData/unitChange',headMenuKey:SystemEnum.ZBGL,code:'',name:'单位转换'},// 企业指标
{path:'/enterprise/project',headMenuKey:SystemEnum.ZBGL,code:'',name:'整体工程指标'},{path:'/enterprise/single',headMenuKey:SystemEnum.ZBGL,code:'',name:'单项工程指标'},{path:'/enterprise/unit',headMenuKey:SystemEnum.ZBGL,code:'',name:'单位工程指标'},{path:'/enterprise/inventory',headMenuKey:SystemEnum.ZBGL,code:'',name:'清单综合单价'},{path:'/enterprise/material',headMenuKey:SystemEnum.ZBGL,code:'',name:'工料价格指标'},// 基础数据维护
{path:'/dict/zjzb',headMenuKey:SystemEnum.ZBGL,code:'',name:'数据字典'},{path:'/inventory',headMenuKey:SystemEnum.ZBGL,code:'',name:'基础清单定额库'},{path:'/application-template',headMenuKey:SystemEnum.ZBGL,code:'',name:'指标采集模板'},{path:'/indicator/collectorTemplate',headMenuKey:SystemEnum.ZBGL,code:'630:indtmpl:colltmpl:query',name:'指标模板2'},{path:'/indicator/collectorTemplate/edit',headMenuKey:SystemEnum.ZBGL,code:'630:indtmpl:colltmpl:update',name:'指标模板2'},// 政府发布指标模板
{path:'/official-template/list',headMenuKey:SystemEnum.ZBGL,code:'',name:'政府指标模板'},{path:'/official-template/open',headMenuKey:SystemEnum.ZBGL,code:'',name:'政府指标模板开放'},{path:'/official-template/setting',headMenuKey:SystemEnum.ZBGL,code:'',name:'政府指标模板设置'},// { path: '/field/list',headMenuKey:SystemEnum.jcsj, code: '', name: '字段设置' },
// 项目登记
{path:'/cloud-project/list',headMenuKey:SystemEnum.YJGL,code:'630:perfmgt:project:query',name:'项目登记'},{path:'/cloud-project/analysis',headMenuKey:SystemEnum.YJGL,code:'630:sas:sas:query',name:'项目分析'},// 测试
{path:'/test',code:'',name:'测试'}];