import useGlobalStore from'@/store/useGlobalStore';import{useNavigate}from'react-router-dom';import{getEnterpriseInfo}from'@/api';import{getStorage,setStorage,sleep}from'@/utils/tools';import{message}from'antd';import useCloudStore from'@/pages/cloudProject/store';import{produce}from'immer';import{dataCheck}from'@/api/common';import{useAuth}from'./useAuth';export function useFetch(){const navigate=useNavigate();const{getAuthPathByCodes}=useAuth();const{resetStore:resetCloudStore}=useCloudStore();const setGlobalState=useGlobalStore.setState;const{systemKey,setAuthorized,setGlobalInfo,setAuthCodes,menuKeysMap}=useGlobalStore(s=>({systemKey:s.systemKey,setAuthorized:s.setAuthorized,setGlobalInfo:s.setGlobalInfo,setAuthCodes:s.setAuthCodes,resetGlobalStore:s.resetGlobalStore,menuKeysMap:s.menuKeysMap}));// 登录或者刷新时选择企业
const selectEnterprise=async(epid,options,callback)=>{const{isRefresh}=options||{};let enterpriseInfo=null;let newTenantId=null;try{var _enterpriseInfo,_enterpriseInfo2,_enterpriseInfo3;let enterpriseRes=null;enterpriseRes=await getEnterpriseInfo({epid,token:getStorage('token')||''});if(enterpriseRes){enterpriseInfo=enterpriseRes.enterpriseInfo;newTenantId=enterpriseRes.tenantId;if(enterpriseInfo){// 获取企业的时候检查数据
dataCheck({"objectType":0});}}else{message.warning("\u4F60\u7684\u8D26\u53F7\u5728".concat(getStorage('enterpriseName'),"\u7684\u6388\u6743\u5DF2\u5931\u6548\u3002\u5DF2\u4E3A\u4F60\u5207\u6362\u5230\u9ED8\u8BA4\u7528\u6237\u7A7A\u95F4\uFF01")||enterpriseRes.message);await sleep(1000);enterpriseRes=await getEnterpriseInfo({epid:null,token:getStorage('token')||''});if(enterpriseRes){enterpriseInfo=enterpriseRes.enterpriseInfo;newTenantId=enterpriseRes.tenantId;}}setGlobalInfo({currentEnterprise:enterpriseInfo,tenantId:newTenantId});setStorage('tenantId',newTenantId);setStorage('epid',(_enterpriseInfo=enterpriseInfo)===null||_enterpriseInfo===void 0?void 0:_enterpriseInfo.epid);setStorage('enterpriseName',(_enterpriseInfo2=enterpriseInfo)===null||_enterpriseInfo2===void 0?void 0:_enterpriseInfo2.nameCn);const newAuthCodestr=((_enterpriseInfo3=enterpriseInfo)===null||_enterpriseInfo3===void 0?void 0:_enterpriseInfo3.authCodes)||'';if(newAuthCodestr){setAuthCodes(newAuthCodestr.split(';'));}else{setAuthCodes([]);}const newAuthCodes=(newAuthCodestr===null||newAuthCodestr===void 0?void 0:newAuthCodestr.split(';'))||[];// 计算菜单权限
// 所有有权限的config
const authPaths=getAuthPathByCodes(newAuthCodes);// 根据权限配置计算菜单
const newMenuKeysMap=produce(menuKeysMap,draftState=>{Object.keys(draftState).forEach(key=>{const menus=draftState[key];// 对于模块菜单列表
// note: 后续在优化，通过所有模块的菜单的权限进行判断是否隐藏整个菜单权限
menus.forEach(item=>{if(item.children){var _item$children;item.children=((_item$children=item.children)===null||_item$children===void 0?void 0:_item$children.filter(child=>{return authPaths===null||authPaths===void 0?void 0:authPaths.includes(child.path);}))||[];}});// draftState[key] = draftState[key].filter(item => item.children?.length > 0)
// return draftState.filter(item => item.children.length > 0)
});});setGlobalState({menuKeysMap:newMenuKeysMap});// 不是刷新，目前只有登录才会到这里
if(!isRefresh){var _newTenantId;if((_newTenantId=newTenantId)!==null&&_newTenantId!==void 0&&_newTenantId.startsWith('E')){// navigate('/cloud-project/list')
// navigate('/project-center/list')
navigate('/indicator/englist');}else{// navigate('/cost/list')
navigate('/indicator/englist');}}// TODO: 刷新uselocation 拿到还是旧的路由，导致权限出现异常页面，原因不明
await sleep(200);setAuthorized(true);}catch(error){console.log(error);}};// 下拉选择企业
const changeEnterprise=async(epid,callback)=>{try{const enterpriseRes=await getEnterpriseInfo({epid,token:getStorage('token')||''});// 获取企业的时候检查数据
// dataCheck({ "objectType": 0 })
if(enterpriseRes){const enterpriseInfo=enterpriseRes.enterpriseInfo;const newTenantId=enterpriseRes.tenantId;setStorage('tenantId',newTenantId);setStorage('epid',enterpriseInfo===null||enterpriseInfo===void 0?void 0:enterpriseInfo.epid);setStorage('enterpriseName',enterpriseInfo===null||enterpriseInfo===void 0?void 0:enterpriseInfo.nameCn);// if (isPersonal()) {
//   navigate('/cost/list')
// }
// navigate('/project-center/list')
navigate('/indicator/englist');window.location.reload();// 刷新页面，清空store
}}catch(error){console.log(error);}};return{selectEnterprise,changeEnterprise};}