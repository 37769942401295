import{get,post}from"@/utils/request";// 转拼音
export const getCnPy=data=>post('/cost-cloud-support/common/cn_2_py',data);export const refreshSession=data=>get('/cost-cloud-support/common/keepalive',data);// 通用表达式计算接口
export const calcByServer=data=>post('/cost-cloud-support/common/calc',data);// 单位转换
export const getUnitCollection=()=>get('/cost-cloud-indicator/unit_convert/map');// 单位别名接口
export const getUnitAliasList=()=>get('/cost-cloud-indicator/unit_alias/page');// 获取造价上传url
export const getCostUploadUrlApi=params=>get('/cmp-indicator/cost/project_upload_info',params);// 获取造价下载url
export const getCostDownUrlApi=params=>get('/cmp-indicator/cost/project_download_url',params);// 获取锁接口
export const getIndicatorLock=function(params){let config=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};return get('/cmp-indicator/common/lock',params,config);};// 释放锁接口
export const releaseIndicatorLock=params=>get('/cmp-indicator/common/lock/release',params);export const releaseIndicatorPostLock=params=>post('/cmp-indicator/common/lock/release',params);// 基础配置
export const getCommonConfig=()=>get('/cmp-indicator/common/basic_config_info');// 获取云工程上传url
export const getCloudProjectUploadUrlApi=params=>post('/cost-cloud-cloudproject/file/queryUploadAddress',params);// 获取云工程下载url
export const getCloudProjectDownUrlApi=params=>post('/cost-cloud-cloudproject/file/queryDownloadAddress',params);