import{useEffect}from'react';const TokenHandler=props=>{console.log("redirect_logic");const init=async()=>{// 获取当前URL中的所有参数
// const searchParams = new URLSearchParams(window.location.search);
const url=new URL(window.location.href);// 检查 hash 部分是否也包含 token 参数
const hashParams=new URLSearchParams(window.location.hash.split('?')[1]||'');const hashToken=hashParams.get('token');// 检查普通查询参数中的 token
const queryToken=url.searchParams.get('token');// const token = searchParams.get('token');
// 重定向到清理后的URL
const token=hashToken||queryToken;if(token){// 将token存储到localStorage
localStorage.setItem('token',token);// 移除URL中的token参数
// searchParams.delete('token');
// 重定向到清理后的URL
// const newUrl = `/#/indicator/englist`
window.location.replace("".concat(window.location.origin,"/#/indicator/englist"));// 如果必须要刷新页面，可以设置一个很短的延时
setTimeout(()=>{window.location.reload();},100);return;}};useEffect(()=>{init();},[]);return null;// 这是一个纯逻辑组件，不需要渲染任何内容
};export default TokenHandler;