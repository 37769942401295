export const gridOptions={// enableSorting: false, // 确保禁用排序功能
gridWidth:'100%',showHeaderRow:false,// enableSorting: false,  // 添加这一行来全局禁用排序
// gridHeight: tableWrapHeight,
autoEdit:true,// 决定了表格单元格是否在点击后立即进入编辑模式。
autoCommitEdit:true,// 是否自动提交编辑
enableFiltering:true,// 启用过滤功能，树结构需要这个属性
enableTreeData:true,// 启用树状数据，必须启用此标志以使过滤和排序按预期工作
multiColumnSort:false,// 禁用多列排序，因为树状数据不支持多列排序
gridMenu:{hideToggleFilterCommand:true,// 隐藏过滤菜单命令
hideClearAllSortingCommand:true// 隐藏清除排序菜单命令
},autoResize:{container:'#indicator_zjzb_table_container111',rightPadding:0,bottomPadding:0,calculateAvailableSizeBy:'container'},enableAutoResize:true,treeDataOptions:{identifierPropName:'id',parentPropName:'pid',columnId:'name',// 指定树状数据的列ID
childrenPropName:'children',// 指定子项属性名称
autoApproveParentItemWhenTreeColumnIsValid:true,autoRecalcTotalsOnFilterChange:false,excludeChildrenWhenFilteringTree:false,initialSort:{columnId:'sort',direction:'asc'}}};