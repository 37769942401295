import{get,post}from"@/utils/request";import qs from"qs";// 基础信息
export const addSystemBaseDict=data=>post('/cc-dictionary/system/dict/basic/add',data,{},'custom');// 树形基础字典
export const addSystemBaseTreeDict=data=>post('/cc-dictionary/system/dict/tree/add',data,{},'custom');export const updateSystemBaseDict=data=>post('/cc-dictionary/system/dict/basic/update',data,{},'custom');export const deleteSystemBaseDict=data=>post('/cc-dictionary/system/dict/basic/delete',data,{},'custom');// 获取详情
export const getSystemBaseDictDetail=data=>get('/cc-dictionary/system/dict/basic/details',data,{},'custom');export const getSystemBaseDictBatchDetail=data=>get('/cc-dictionary/system/dict/basic/details/batch',data,{},'custom');export const moveSystemBaseDictItem=data=>post('/cc-dictionary/system/dict/basic/move',data,{},'custom');export const getSystemBaseDictParent=parmas=>get('/cc-dictionary/system/dict/basic/parent',parmas,{},'custom');// 根据code查询基础字典项
export const getSystemDictBaseList=data=>get('/cc-dictionary/system/dict/basic',data,{},'custom');// 保存树形属性字典项
export const addSystemTreeDictAttributeItem=data=>post('/cc-dictionary/system/dict/dict/tree-attribute',data,{},'custom');// 保存字典项
export const addSystemDictAttributeItem=data=>post('/cc-dictionary/system/dict/attribute/add',data,{},'custom');export const updateSystemDictAttributeItem=data=>post('/cc-dictionary/system/dict/attribute/update',data,{},'custom');export const moveSystemBaseTreeDictItem=data=>post('/cc-dictionary/system/dict/tree/move',data,{},'custom');// 批量获取数据
export const getSystemBatchDictListByGroupCode=data=>{return get('/cc-dictionary/system/dict/basic/groupCodes',data,{},'custom');};// 删除字典项
export const deleteSystemDictItem=data=>post('/cc-dictionary/system/dict/attribute/delete',data,{},'custom');// 删除字典项
export const deleteSystemTreeDictItem=data=>post('/cc-dictionary/system/dict/tree/delete',data,{},'custom');// 字典项列表
// 查询动态属性列表
export const getSystemBaseDictAttributeList=data=>get('/cc-dictionary/system/dict/attribute',data,{},'custom');// 根据条件去检索属性字典项
export const getSystemDictAttributesListByCondiiton=data=>post('/cc-dictionary/system/dict/dict/attribute/param',data,{},'custom');// 树形字典
export const addSystemTreeDictItem=data=>post('/cc-dictionary/system/dict/tree/add',data,{},'custom');export const editSystemTreeDictItem=data=>post('/cc-dictionary/system/dict/tree/add',data,{},'custom');// 获取groupCode下面的所有树
export const getSystemTreeDictList=data=>// get<any>('/cc-dictionary/system/dict/tree/group-code', data, {}, 'custom')
get('/cc-dictionary/system/dict/dict/tree-attribute/group-code',data,{},'custom');// 根据字典code查询树型字典项
export const getSystemBaseDictTreeItem=data=>{return get('/cc-dictionary/system/dict/tree',data,{},'custom');};// const isSystemDict = () => {
//   return location.href.includes('systemDict');
// }
// 租户字典
export const getTreeDictList=data=>{return get('/cc-dictionary/v2/dict/dict/tree-attribute/group-code',data,{},'custom');};// 根据字典code查询树型字典项
export const getBaseDictTreeItem=data=>{return get('/cc-dictionary/dict/tree',data,{},'custom');};// 根据字典code查询动态属性字典项
export const getBaseDictAttributeList=data=>{return get('/cc-dictionary/v2/dict/attribute',data,{},'custom');};// 根据条件去检索属性字典项
export const getDictAttributesListByCondiiton=(data,query)=>{return post("/cc-dictionary/v2/dict/dict/attribute/param".concat(query?"?".concat(qs.stringify(query)):''),data,{},'custom');};// 根据字典code查询动态属性字典项
export const getBaseDictList=data=>{// return get<any>('/cc-dictionary/dict/basic', data, {}, 'custom')
return get('/cc-dictionary/v2/dict/basic',data,{},'custom');};// 查询所有基础字典组
export const getBaseDictParent=data=>{return get('/cc-dictionary/dict/basic/parent',data,{},'custom');};// 复制
export const copyDict=data=>post('/cc-dictionary/dict/dict/copy',data,{},'custom');// 删除
export const deleteDictAttributeItem=data=>{return post('/cc-dictionary/dict/attribute/delete',data,{},'custom');};// V2接口-删除动态属性字典项
export const deleteV2DictAttributeItem=data=>{return post('/cc-dictionary/v2/dict/attribute/delete',data,{},'custom');};// 核心接口-删除字典项
export const deleteCoreDictAttributeItem=data=>{return post('/cc-dictionary/core/dict/remove',data,{},'custom');};// 删除树形字典项
export const deleteTreeDictItem=data=>{return post('/cc-dictionary/dict/tree/delete',data,{},'custom');};// 移动
export const moveBaseDictItem=(data,query)=>{// return post<any>('/cc-dictionary/dict/basic/move', data, {}, 'custom')
return post("/cc-dictionary/v2/dict/basic/move".concat(query?"?".concat(qs.stringify(query)):''),data,{},'custom');};// 获取详情
export const getBaseDictDetail=data=>{return get('/cc-dictionary/v2/dict/basic/details',data,{},'custom');};// 根据dictCodes批量查询基础字典详细信息
export const getBaseDictBatchDetail=data=>{// return get<any>('/cc-dictionary/v2/dict/basic/details/batch', data, {}, 'custom')
return post('/cc-dictionary/business/dict/dictCodes',data,{},'custom');};// 保存动态属性字典项
export const addTreeDictAttributeItem=data=>{return post('/cc-dictionary/v2/dict/dict/tree-attribute',data,{},'custom');};// 更新动态属性字典项
export const updateDictAttributeItem=data=>{return post('/cc-dictionary/dict/attribute/update',data,{},'custom');};// 核心接口-修改字典的动态属性值
// export const updateCoreDictAttributeValItem = (data) => {
//   if (isSystemDict()) return updateSystemDictAttributeItem(data)
//   return post<any>('/cc-dictionary/core/dict/attr/val/modify', data, {}, 'custom')
// }
// 核心接口-修改字典基本属性
export const updateCoreDictAttributeItem=(data,systemData)=>{return post('/cc-dictionary/core/dict/modify',data,{},'custom');};// 核心接口-修改或添加字典条目下的属性值（静态或动态属性）
export const updateBusinessDictAttributeItem=data=>{return post('/cc-dictionary/business/dict/modify',data,{},'custom');};// // 核心接口-修改或添加字典条目下的属性值（静态或动态属性）V2版本
// export const updateBusinessDictAttributeItemV2 = (data) => {
//   return post<any>('/cc-dictionary/business/dict/modify', data, {}, 'custom')
// }
// 移动树型字典项
export const moveBaseTreeDictItem=(data,query)=>{// return post<any>('/cc-dictionary/v2/dict/tree/move', data, {}, 'custom')
return post("/cc-dictionary/v2/dict/tree/move".concat(query?"?".concat(qs.stringify(query)):''),data,{},'custom');};export const updateBaseDict=data=>{return post('/cc-dictionary/dict/basic/update',data,{},'custom');};export const deleteBaseDict=data=>{return post('/cc-dictionary/dict/basic/delete',data,{},'custom');};// 批量获取数据
export const getBatchDictListByGroupCode=data=>{// return get<any>('/cc-dictionary/v2/dict/basic/groupCodes', data, {}, 'custom')
return post('/cc-dictionary/business/dict/groupCodes',data,{},'custom');};// 批量添加，但不建议一次添加过多字典
export const addBusinessDict=data=>{return post("/cc-dictionary/business/dict/add",data,{},'custom');};// 获取默认字典版本
export const getDefaultDictVersion=templateCode=>{return get("/cc-dictionary/business/dict/template/default/".concat(templateCode));};export const updateDefaultDictVersion=data=>{return post("/cc-dictionary/business/dict/template/default",data,{},'custom');};// 发布字典
export const publishDictVersion=data=>{return post("/cc-dictionary/business/dict/template/publish",data,{},'custom');};// 重置字典
export const resetUnpublishDict=data=>{return post("/cc-dictionary/business/dict/template/reset",data,{},'custom');};// 切换状态
export const switchDictVersion=data=>{return post("/cc-dictionary/business/dict/template/switch2editstatus",data);};// 获取字典版本
export const getDictVersions=data=>{return get("/cc-dictionary/business/dict/template/".concat(data===null||data===void 0?void 0:data.templateCode,"/version"));};// 获取系统通用的字典设置
export const getDictSystemcommon=data=>{return post("/cc-dictionary/business/dict/systemcommon",data,{},'custom');};// 查新给出id对应的字典的最新版本数据字典属性
export const getLastVersionDict=data=>{return post("/cc-dictionary/business/dict/lastversiondicts",data,{},'custom');};// 查询给出id对应的字典条目信息
export const getDictByIds=data=>{return post("/cc-dictionary/business/dict/findbyids",data,{},'custom');};